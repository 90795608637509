import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { compose } from "recompose";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import { withAuthorization } from "../components/Session";
import EmbedYouTubeVideo from "../components/EmbedYouTubeVideo";
import PageHeader from "../components/PageHeader";
import * as ROUTES from "../constants/routes";

function ExerciseLibraryPostTemplateBase({ description, title, url, helmet }) {
  return (
    <ModalRoutingContext.Consumer>
      {({ modal }) =>
        modal ? (
          <div className="container content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                  {title}
                </h1>
                <p>{description}</p>
                <EmbedYouTubeVideo url={url} title={title} />
              </div>
            </div>
          </div>
        ) : (
          <div className="exercise-library-page">
            <PageHeader
              title={title}
              breadcrumb={
                <Link to={ROUTES.EXERCISE_LIBRARY}>Exercise Demos</Link>
              }
            />
            <section className="section">
              {helmet || ""}
              <div className="container content">
                <div className="columns">
                  <div className="column is-10 is-offset-1">
                    <p>{description}</p>
                    <EmbedYouTubeVideo url={url} title={title} />
                  </div>
                </div>
              </div>
            </section>
          </div>
        )
      }
    </ModalRoutingContext.Consumer>
  );
}

ExerciseLibraryPostTemplateBase.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  helmet: PropTypes.object,
};

const condition = (authUser) => !!authUser;

const ExerciseLibraryPostTemplate = compose(withAuthorization(condition))(
  ExerciseLibraryPostTemplateBase
);

const ExercisePost = ({ data }) => {
  const { markdownRemark: post } = data;
  const content = (
    <ExerciseLibraryPostTemplate
      description={post.frontmatter.description}
      title={post.frontmatter.title}
      url={post.frontmatter.url}
      helmet={
        <Helmet titleTemplate="%s | Exercise Demos | Kristielengel.com">
          <title>{`${post.frontmatter.title}`}</title>
          <meta
            name="description"
            content={`${post.frontmatter.description}`}
          />
          <meta name="og:video" content={post.frontmatter.url} />
          <meta name="og:title" content={post.frontmatter.title} />
        </Helmet>
      }
    />
  );
  return (
    <ModalRoutingContext.Consumer>
      {({ modal }) => (modal ? content : <Layout>{content}</Layout>)}
    </ModalRoutingContext.Consumer>
  );
};

ExercisePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ExercisePost;

export const pageQuery = graphql`
  query ExercisePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        url
      }
    }
  }
`;
